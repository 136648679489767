/* BEGIN TRANSLATION */

const title = {
    app: "Live Assistant",
    information: "Maklumat",
    "error-occurred": "Ralat Berlaku",
    "management-login": "Log Masuk Pengurusan",
    "running-number": "Nombor Larian",
    "system-setting": "Penetapan Sistem",
    company: "Syarikat",
    "company-address": "Alamat Syarikat",
    user: "Pengguna",
    customer: "Pelanggan",
    "customer-group": "Kumpulan Pelanggan",
    "discard-changes": "Membuang Perubahan",
    "delete-record": "Padam Rekod",
    create: "Baru",
    modify: "Ubah",
    profile: "Profil",
    address: "Alamat",
    "new-address": "Alamat Baru",
    "edit-address": "Ubah Alamat",
    "customer-address": "Alamat Pelanggan",
    "live-session": "Sesi Siaran",
    confirmation: "Pengesahan",
    "facebook-account": "Facebook Akaun",
    currency: "Mata Wang",
    "session-closing": "Sesi Penutup",
    "product-sales": "Jualan Produk",
    "import-live-session-detail": "Import Sesi Siaran Perincian",
    "notify-customer": "Maklumkan Kepada Pelanggan",
    "shipping-address": "Alamat Penghantaran",
    checkout: "Checout",
    payment: "Pembayaran",
    "customer-order": "Pesanan Pelanggan",
    "customer-payment": "Pembayaran Pelanggan",
    attachments: "Lampiran",
    country: "Negara",
    state: "Negeri",
    city: "Bandar",
    order: "Pesanan",
    "shipping-fee": "Kos penghantaran",
    voucher: "Baucar",
    pricing: "Harga",
    "payment-detail": "Pembayaran Perincian",
    "cancel-order": "Batalkan Pesanan",
    "finalize-order": "Muktamadkan Pesanan",
    "reject-order": "Tolak Pesanan",
    "reverse-order": "Membalikkan Pesanan",
    "transfer-request": "Permintaan Memindahan",
    "finalize-payment": "Muktamadkan Pembayaran",
    "approve-payment": "Luluskan Pembayaran",
    "cancel-payment": "Batalkan Pembayaran",
    "approve-transfer": "Luluskan Pemindahan",
    "cancel-transfer": "Batalkan Pemindahan",
    transfer: "Pindah",
    event: "Acara",
    "wallet-balance": "Baki Dompet",
    "customer-payment-summary": "Ringkasan Pembayaran Pelanggan",
    "upload-receipt": "Muat Naik Resit",
    postage: "Pos",
    shipment: "Penghantaran",
    "expiration-date": "Tarikh Luput",
    "change-password": "Tukar Kata Laluan"
};

/* ENDED TRANSLATION */
export { title };